import React from "react";
import "./Base.css";
import { Body } from "./Body/Body";

export const Base = () => {
  return (
    <>
      <Body />
    </>
  );
};
